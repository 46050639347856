import React, {Component} from "react";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import "./ProductPriceInfo.css";

/**
 * Price component for the products on S2D portal.
 * If the product is on sale, the regular price is displayed with a line through text. Otherwise, only the final price is
 * displayed to the page.
 */
export default class PriceInfo extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const product = this.props.product;
        const priceRenderer = this.props.renderer;
        const finalPrice = priceRenderer === 'checkout' ? product.qty * product.final_price : product.final_price;
        const regularPrice = priceRenderer === 'checkout' ? product.qty * product.regular_price : product.regular_price;

        return (
            product.on_sale ?
                <Col className={this.props.renderer + ' price-section'} sm={10}>
                    <span className={'final-price'}>
                        ${parseFloat(finalPrice).toFixed(2)}
                    </span>
                    <span className={'old-price'}>
                        ${parseFloat(regularPrice).toFixed(2)}
                    </span>
                </Col>
                :
                <Col sm={5}>
                    <span style={{fontWeight: 700}}> ${parseFloat(finalPrice).toFixed(2)} </span>
                </Col>
        )
    }
}

PriceInfo.propTypes = {
    product: PropTypes.object.isRequired,
    renderer: PropTypes.string.isRequired
}